import React, { useEffect, useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import Aos from "aos";
import "aos/dist/aos.css";
import { HashLink as Link } from "react-router-hash-link";
import NavLogo from '../assets/logowhite.png'
import { useNavigate } from "react-router-dom";


const Nav = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    Aos.init();
  });

  return (
    <>
    <nav id='home'  data-aos="fade-down"
                data-aos-duration="1200"
                data-aos-delay="1500">
      <div className="nav__row">
        <div className="nav__items"  >
          <div className="nav__menu">
            <IoMenu onClick={() => setOpenMenu(true)} />
          </div>
          <div className="nav__logo">
            <img src={NavLogo} alt="" className="nav__logo--img" onClick={() => navigate('/')}/>
           {/* <h1><a href="#home" className="nav__logo--link">Good Grass</a></h1> */}
          </div>
          <div className="nav__cta">
            
          </div>
        </div>
      
      </div>
    </nav>
    {openMenu ? (
          <div
            className="nav__sidebar"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <ul>
              <li><a onClick={() => {setOpenMenu(false); navigate('/')}}>Home</a></li>
              <li><a onClick={() => {setOpenMenu(false); navigate('/cleanz-all')}}>Cleanz All</a></li>
              <li><a onClick={() => {setOpenMenu(false); navigate('/greenz-all')}}>Greenz All</a></li>
            </ul>
            <div className="sidebar__close">
              <IoClose onClick={() => setOpenMenu(false)} />
            </div>
          </div>
        ) : null}
    </>
  );
};

export default Nav;
